@import "App";
.serviceContainer{
  .w-95{
    width: 95%;
  }
  .filesContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include breakpoint(xs){
      flex-direction: column;
    }
  }
  .assigneeSelect{
    margin-top: 40px;
    margin-bottom: 20px;
    .react-select__control{
      min-height: 50px !important;
    }
  }
  .dateTimeContainer{
    .serviceDate.dateContainer{
      width: 100%;
      margin-top: 10px;
      &:first-child{
        margin-top: unset;
        margin-bottom: 20px;
      }
    }
    .saveTimeLogoService{
      width: 60px;
      height: calc(100% - 28px);
      position: absolute;
      bottom: 2px;
      right: 1px;
      background-color: #EEEEEE;
      padding: 6px;
      z-index: 1;
      border-radius: 0 9px 9px 0;
      cursor: pointer;
    }
    .clearDate{
      top: 37px;
    }
  }
  .createNotRegisteredClient{
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--purple);
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    z-index: 1;
  }
}