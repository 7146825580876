
.loading {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;

  &.relative {
    right: 0;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    position: absolute;

    .wrapper {
        top: calc(50% - 50px);
    }
  }

    &.loadMore {
        top: unset;
        bottom: 0;
    }

  p {
    position: relative;
    top: calc(50vh - 50px);
    text-align: center;
    color: white;
    font-size: 18px;
    white-space: pre;
  }

  .loader {
    display: flex;
    align-items: center;
    svg {
      height: 100px;
      width: 100px;
      margin: auto;
      display: block;
        animation-name: spin;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      // fill: rgba(2, 110, 185, 0.42);
      //fill: rgba(38, 50, 56, 0.42); //main
    }
  }

  .logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;
    margin: auto;
    width: 63px;

    svg {
      width: 100%;
      // fill: rgba(2, 110, 185, 0.42);
      //fill: rgba(2, 110, 185, 1);
    }
  }

  .wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    //@include breakpoint(xs){
    //  padding-top: 40px;
    //}
  }

}

.backgroundTransparent {
	.wrapper {
		background-color: transparent!important;
	}
}

button > .loading {
  background-color: transparent;
  width: unset;
  height: unset;
  position: unset;
  //margin-top: -9px;
  //margin-left: -3px;


    .wrapper {
      position: unset;
      top: unset;

      svg {
        height: 35px;
        fill: #fff;
          width: unset;
      }

    }

}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
