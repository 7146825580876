@import '../../App.scss';

.header {
    background: #fff;
    .logo {
        width: 130px;

        @include breakpoint(xs){
            min-width: 100px;
        }
        img {
            width: 220px;
            margin: 0 auto;
            height: 84px;
            object-fit: cover;
        }
    }
    .collapse-bar {
        padding: 18px;
        @include breakpoint(xs){
            padding: 8px;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1111;
            background-color: white;
            border-radius: 5px;
            border: solid 1px var(--dark-blue);
        }
        &.opened{
            @include breakpoint(xs){
                right: 10px;
                left: unset;
            }
        }
        margin: auto 0;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        .collapse-button {
            width: 30px;
            height: 20px;
            @include breakpoint(xs){
                width: 20px;
                height: 15px;
            }
            background-color: transparent;
            border: 0;
            color: var(--dark-blue);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            &.opened{
                div:first-child{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: rotate(45deg);
                }
                div:nth-child(2){
                    left: 50%;
                    width: 0;
                }
                div:nth-child(3){
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: rotate(-45deg);
                }
            }
            div{
                position: absolute;
                left: 0;
                transition: 100ms;
                width: 100%;
                height: 1px;
                border-radius: 2px;
                background-color: black;
            }
            div:first-child{
                height: 2px;
                top: 0;
            }
            div:nth-child(2){
                top: 50%;
                transform: translateY(-50%);
            }
            div:nth-child(3){
                height: 2px;
                bottom: 0;
            }
        }
    }
    .box-left-shadow {
        box-shadow: 2px 0px 6px 0px rgba(0,0,0,0.4);
        -webkit-box-shadow: 2px 0px 6px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 2px 0px 6px 0px rgba(0,0,0,0.4);
        width: fit-content;
        .search-input {
            width: 350px;
            padding-left: 30px;

            @include breakpoint(xs){
                width: 80px;
                padding-left: 15px ;
            }
            .input-wrapper {
                margin: 20px 0;
            }
            input {
                border: 0;
                padding-left: 30px;
            }
            input ~ span {
                padding-left: 30px;
                line-height: 10px;
                color: #4d4f5cb3;
            }
            .search-icon {
                left: 0;
                right: auto;
            }
        }
    }
}