.gatheredInfoWrapper {
  margin-top: -10px;
  padding: 20px 10px 10px 10px;
  width: 100%;
  height: fit-content;
  background-color: #f3f3f5;
  border: solid 1px var(--light-blue);
  border-radius: 0 0 10px 10px;

  .gatheredInfoContainer {
    width: 100%;
    height: fit-content;
  }
  .col-flex {
    width: 50%;
    padding-right: 40px;
  }
}
@media screen and (max-width: 500px) {
  .gatheredInfoWrapper {
    .col-flex {
        width: 100%;
        padding: 0;
    }
  }

}
p.labelData {
  font-weight: bold;
  // margin-left: 20px;
  // width: 35%;
  margin-bottom: 5px;
  min-height: 30px;
}
.data {
  align-items: center;

  p:last-child {
    border-bottom: 1px solid var(--light-blue);
    // border-bottom: 1px solid var(--dark-blue);
    padding: 7px 7px 7px 0px;
    font-size: 18px;
    width: 100%;
  }
  img.product-pic.m-1 {
    padding-left: 10px;
  }
  
}

.comment {
  // margin-left: 20px;
  p:first-child {
    font-weight: bold;
  }

  p:last-child {
    border: 1px solid var(--light-blue);
    padding: 20px 20px 70px;
    border-radius: 10px;
    min-height: 150px;
  }
}
.suggestedClients-container-measures {
  padding-top: 35px;
  background-color: white;
  position: absolute;
  top: calc(100% - 40px);
  left: 0;
  z-index: 199;
  width: 100%;
  height: max-content;
  max-height: 300px;
  overflow-y: auto;
  border: solid 1px var(--light-blue);
  border-radius: 0 0 10px 10px;
  .suggestedClients {
    padding: 10px 15px;
    font-size: 15px;
    &:hover,
    &.selectedOption {
      background-color: var(--light-blue);
    }
  }
}
.nameSearch {
  position: relative;
  img {
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 30px;
    z-index: 1;
    border-radius: 50%;
  }
  input{
    cursor: pointer !important;
  }
}

.data-section {
  min-height: 230px;
}

.gatheredInfoContainer {
  .row {
    display: flex; /* equal height of the children */
    .col {
      flex: 1; /* additionally, equal width */
      margin: 20px 30px 0px 15px;
      position: relative;
      min-height: 150px;
      border: 1px solid var(--light-blue);
      border-radius: 10px;
      p:first-child {
        position: absolute;
        top: -30px;
        font-weight: bold;
        left: 0px;
      }
    }
  }
}
