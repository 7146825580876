@import  '../../App.scss';

.warehouseContainer{
    padding: 25px;
    .wareHouseTitle{
        font-size: 18px;
        font-weight: 600;
    }
}
.labelContainer{
    padding: 10px;
    @include breakpoint(xs){
        padding: 0;
    }
}
.projectInformation{
    transition: 200ms;
    width: 100%;
    height: fit-content;
    margin: 10px 0;
    background-color: #f3f3f5;
    border-radius: 10px;
    .projectName{
        cursor: pointer;
        border-radius: 10px;
        background-color: var(--dark-blue);
        padding: 12px;
        color: var(--light-blue);
        overflow: hidden;
        p{
            font-size: 18px;
            margin: 0;
        }
    }
}
.warehouseAssigneesContainer{
    padding-left: 20px;
}
.transportAssigneeContainer{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    .assigneeSelectContainer{
        position: relative;
        margin: 10px 0;
        .minusHolder{
            position: absolute;
            top: 50%;
            right: 20px;
            z-index: 1;
            transform: translateY(-50%);
            border-radius: 50%;
            width: 18px;
            height: 18px;
            border: solid 1px var(--dark-blue);
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                width: 60%;
                height: 1px;
                border-radius: 1px;
                margin-top: 1px;
                background-color: var(--dark-blue);
                transform: translateY(-50%);
            }
        }
    }
}
.projectContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 100px;
    .projectCard{
        transition: 200ms;
        padding-right: 10px;
        width: 100%;
        .projectInformation{
            &:not(.noMinHeight){
                min-height: 500px;
            }
            .projectInformationContainer{
                &.maxHeight{
                    max-height: 100%;
                    min-height: 100%;
                }
            }
        }
        &.expanded{
            width: 100%;
            @include breakpoint(xs){
                .projectInformation{
                    display: flex;
                    flex-wrap: wrap;
                    .projectInformationContainer{
                        width: 100% !important;
                        .productsContainer{
                            max-height: 400px;
                        }
                    }
                    .warehouseExtraInformationContainer{
                        width: 100%;
                    }
                }
                .editWarehouseRecordContainer{
                    flex-direction: column !important;
                    .file-and-input-container{
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
            .projectInformation{
                display: flex;
                flex-wrap: wrap;
                .projectInformationContainer{
                    width: 50%;
                }
            }
        }
        @include breakpoint(sm){
            width: 100%;
        }
        .projectName{
            background-color: rgba(165, 201, 255, 0.4);
            color: var(--dark-blue);
        }
        .titleField{
            font-size: 18px;
            font-weight: bold;
        }
        .productsContainer{
            &.maxHeight{
                max-height: 260px;
                min-height: 100%;
            }
        }
        .productContainer{
            padding: 16px 12px 12px 12px;
            .titleField{
                color: var(--dark-blue);
            }
            p.w-33{
                padding-left: 10px;
                margin: 0;
            }
            &.products{
                display: none;
                position: relative;
                .validCheck{
                    position: absolute;
                }
                &::before{
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: var(--light-blue);
                    height: 1px;
                    width: 98%;
                }
            }
        }
        .assigneeSelect{
            padding: 2px 0;
        }
    }
}
.warehouseClientInformationContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.editWarehouseRecordContainer{
    display: flex;
    padding: 12px;
    @include breakpoint(xs){
        flex-direction: column;
    }
    .input-wrapper{
        margin: 0 !important;
    }
    .editButton{
        margin: 0 !important;
        padding: 10px !important;
        font-size: 18px !important;
    }
    .file-and-input-container{
        width: 50%;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: start;
        @include breakpoint(xs){
            width: 100%;
        }
        .fileIcon{
            width: 35px;
            height: 35px;
            font-size: 18px;
            color: white;
            background-color: var(--dark-blue);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


.warehouseListMobileDesign{
    padding: 3rem;
    @include breakpoint(xs){
        padding: 1rem;
    }
}
.expandButton{
    width: 35px;
    height: 135%;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    &.mobile{
        span{
            transform: rotate(90deg);
        }
    }
    span{
        color: white;
    }
}
.quantityInputContainer{
    input{
        background-color: transparent;
        border: solid 1px var(--light-blue);
        width: 60px;
        outline: none;
    }
}

.warehouseExtraInformationContainer{
    width: 100%;
    margin-top: 5px;
    overflow: auto;
    .warehouseRecordContainer{
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        .recordCard{
            width: 350px;
            height: fit-content;
            min-height: 100px;
            padding: 20px;
            margin: 5px;
            border-radius: 10px;
            background-color: rgba(165, 201, 255, 0.4);
            transition: 200ms;
            &.selected{
                box-shadow: rgba(0, 0, 0, 0.2) 5px 2px 10px;
            }
            .productRow{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                hr{
                    background-color: black;
                    width: 100%;
                    height: 1px;
                    opacity: 0.2;
                    margin: 3px auto;
                }
                p{
                    margin: 0;
                    width: 85%;
                    font-weight: 200;
                    &:nth-child(3){
                        width: 15%;
                        text-align: right;
                    }
                }
            }
        }
    }
}

.print-container.warehouse{
    position: absolute;
    top: 0;
    right: 0;
    .printableWarehouseRecord{
        display: none;
    }
}

.printableWarehouseRecord{
    padding: 30px;
    .pdfTitle {
        font-size: 38px;
        color: var(--dark-blue);
        font-weight: 600;
    }
    .warehouseRecordContainer{
        display: flex;
        justify-content: space-between;
        .warehouseField{
            width: 49%;
            p{
                font-size: 20px;
                border-bottom: solid 1px var(--dark-blue);
            }
            .fieldLabel{
                font-weight: bold;
                margin-bottom: 10px;
                border: none;
            }
        }
    }
    .productContainer{
        width: 100%;
        .productRow{
            display: flex;
            border-top: solid 1px var(--dark-blue);
            border-bottom: solid 1px var(--dark-blue);
            padding: 10px 0;
            &:first-child{
                border-top: solid 2px var(--dark-blue);
            }
            &:last-child{
                border-bottom: solid 2px var(--dark-blue);
            }
            p{
                width: 50%;
                margin-bottom: 0;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}
.warehouseCheckboxesContainer{
    height: 40px;
    width: 80%;
    margin: 5px 5px 5px auto;
    display: flex;
    align-items: center;
    justify-content: end;
    @include breakpoint(xs){
        margin: 0;
        justify-content: space-between;
        width: 100%;
    }
    input{
        margin: 10px;
    }
}
.totalPriceContainerForWarehouse{
    background-color: var(--dark-blue);
    color: white;
    position: fixed;
    z-index: 1;
    bottom: 20px;
    right: 375px;
    display: flex;
    border-radius: 8px;
    padding: 0 20px;
    @include breakpoint(xs){
        right: 10px;
        bottom: 100px;
    }
    p{
        margin: 0;
        font-size: 28px;
        &:first-child{
            margin: 0 10px 0 0;
        }
    }
}

.validCheckContainer.valid{
    color: #1ea644;
}
.validCheckContainer.invalid{
    color: #ff2929;
}
